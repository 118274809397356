import firebase from 'gatsby-plugin-firebase';

export const signIn = async ({ email, password }) => {
  const { user } = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  return user;
};

export const signUp = async (userInfo) => {
  const { user } = await firebase
    .auth()
    .createUserWithEmailAndPassword(userInfo.email, userInfo.password);

  await firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .set({ ...userInfo, id: user.uid });

  return user;
};

export const resetPassword = async (oldPasswowrd, newPassword) => {
  const user = firebase.auth().currentUser;

  if (!user) throw { message: 'Ningún usuario se encuentra logueado' };

  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    oldPasswowrd,
  );

  await user.reauthenticateWithCredential(credential);
  return await user.updatePassword(newPassword);
};

export const recoverPassword = async (email) => {
  const data = await firebase.auth().sendPasswordResetEmail(email);
  return data;
};

export const checkUser = async () => {
  return firebase.auth().currentUser;
};

export const logoutUser = () => {
  return firebase.auth().signOut();
};

export const getUser = async (id) => {
  const res = await firebase.firestore().collection('users').doc(id).get();
  return res.data();
};
