//Funcion para obtener el ancho de la pantalla//
import { window } from 'browser-monads';

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}
