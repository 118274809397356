import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import React from 'react';
import s from './index.module.scss';
import Loader from 'react-loader-spinner';

const Button = ({ className, value, onClick, styles, type, loading }) => {
  return (
    <button
      type={type || 'button'}
      className={s[className]}
      style={{ ...styles }}
      onClick={onClick}
      disabled={loading || false}
    >
      {loading ? (
        <Loader
          type="ThreeDots"
          color="black"
          height={'24px'}
          width={'24px'}
          style={{ margin: '0px', padding: '0px' }}
        />
      ) : (
        value
      )}
    </button>
  );
};

export default Button;
