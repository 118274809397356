import s from './footer.module.scss';
import React, { Fragment } from 'react';

import facebookIcon from '../../../static/icons/social/facebook.png';
import instagramIcon from '../../../static/icons/social/instagram.png';

const Footer = ({ styles }) => {
  return (
    <Fragment>
      <div className={s.container} style={{ ...styles }}>
        <div className={s.imgContainer}>
          <div style={{ cursor: 'pointer' }}>
            <img className={s.icon} alt="facebook-icon" src={facebookIcon} />
          </div>

          <div style={{ cursor: 'pointer' }}>
            <img className={s.icon} alt="instagram-icon" src={instagramIcon} />
          </div>
        </div>

        <div className={s.texts}>
          <p className={s.small}>
            Beber con moderación. Prohibida su venta a menores de 18 años. Favor
            no compartir contenido con menores de edad. Cerveza Corona 2020.
          </p>

          <div className={s.terms}>
            <div className={s.item}>TÉRMINOS DE USO</div>
            <div>|</div>
            <div className={s.item}>POLÍTICAS DE PRIVACIDAD</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
