import { navigate } from 'gatsby';
import React, { Fragment } from 'react';

import Button from '../../button';
import s from './index.module.scss';
import cart_img from '../../../../static/cart.png';
import closeIcon from '../../../../static/icons/closeIcon.png';

const CardItem = ({ item, onDelete }) => {
  const onSubmit = (e) => {
    e.preventDefault();

    navigate('/experiences/pay', {
      state: {
        newReservation: item,
      },
    });
  };

  const onSubmitDelete = (e) => {
    e.preventDefault();
    onDelete(item.id);
  };

  return (
    <Fragment>
      <div className={s.container}>
        <img src={cart_img} alt="img" className={s.cart_img} />

        <div className={s.text}>
          <p className={s.title}>Corona Sessions</p>
          <p className={s.parrafo}>{item.experienceId.title}</p>
          <p className={s.small}>{item.experienceId.ubication}</p>
        </div>

        <img
          src={closeIcon}
          alt="icon"
          className={s.closeIcon}
          onClick={onSubmitDelete}
        />
      </div>

      <div className={s.pricing}>
        <div>{item.people} personas</div>

        <div className={s.btn}>
          <div>total: ${item.total}</div>
          <Button
            type="submit"
            className="auth"
            value="Ir a pagar"
            styles={{
              background: '#ffc72c',
              minWidth: '155px',
              marginTop: '6px',
            }}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CardItem;
