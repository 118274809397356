import short from 'short-uuid';
import firebase from 'gatsby-plugin-firebase';

export const createReservation = async (params) => {
  try {
    const id = short.generate();

    await firebase
      .firestore()
      .collection('reservations')
      .doc(id)
      .set({
        ...params,
        id: id,
        status: 'unpaid',
        date: `${new Date()}`,
      });

    const res = await firebase
      .firestore()
      .collection('reservations')
      .doc(id)
      .get();

    return res.data();
  } catch (err) {
    throw err;
  }
};

export const deleteReservation = async (id) => {
  try {
    const res = await firebase
      .firestore()
      .collection('reservations')
      .doc(id)
      .update({ status: 'cancelled' });

    console.log('updated complete');

    return res;
  } catch (err) {
    throw err;
  }
};

export const checkReservation = async (experienceId, day) => {
  try {
    const res = await firebase
      .firestore()
      .collection('reservations')
      .where('experienceId', '==', experienceId)
      .where('status', '==', 'approved')
      .where('day', '==', day)
      .get();

    return res.docs.map((doc) => doc.data());
  } catch (err) {
    throw err;
  }
};

export const getReservations = async (userId) => {
  try {
    const res = await firebase
      .firestore()
      .collection('reservations')
      .where('userId', '==', userId)
      .where('status', '==', 'approved')
      .get();

    const info = res.docs.map((doc) => doc.data());

    const data = await Promise.all(
      info.map(async (reservation) => {
        const experience = await firebase
          .firestore()
          .collection('experiences')
          .doc(reservation.experienceId)
          .get();

        reservation.experienceId = experience.data();
        return reservation;
      }),
    );

    return data;
  } catch (err) {
    throw err;
  }
};

export const getCar = async (userId) => {
  try {
    const res = await firebase
      .firestore()
      .collection('reservations')
      .where('userId', '==', userId)
      .where('status', '==', 'unpaid')
      .get();

    const info = res.docs.map((doc) => doc.data());

    const data = await Promise.all(
      info.map(async (reservation) => {
        const experience = await firebase
          .firestore()
          .collection('experiences')
          .doc(reservation.experienceId)
          .get();

        reservation.experienceId = experience.data();
        return reservation;
      }),
    );

    return data;
  } catch (err) {
    throw err;
  }
};

export const updateReservation = async (id, link) => {
  try {
    const res = await firebase
      .firestore()
      .collection('reservations')
      .doc(id)
      .update({ linkMp: link });

    return res;
  } catch (err) {
    throw err;
  }
};
