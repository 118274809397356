import { useEffect } from 'react';
import { document } from 'browser-monads';

export const useOutsideClick = (ref, displayMenu, setDisplayMenu) => {
  useEffect(() => {
    function handleClickOutside(e) {
      e.stopPropagation();
      if (ref.current && !ref.current.contains(e.target))
        setDisplayMenu(!displayMenu);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, displayMenu, setDisplayMenu]);
};
