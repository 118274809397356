import React, { useRef } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { deleteReservation } from '../../api/reservations';

import s from './index.module.scss';
import CardItem from './cart-item';
import cart_img from '../../../static/icons/cart.png';

const Cart = ({ dropdown, setDropdown, user, cart, fetchCar }) => {
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, dropdown, setDropdown);

  const handleDelete = async (reservationsId) => {
    await deleteReservation(reservationsId);
    fetchCar();
  };
  return (
    <div className={s.emptyDropdown} ref={wrapperRef}>
      <p>Mi carrito</p>

      {cart && cart.length >= 1 ? (
        <div className={s.cardContainer}>
          <div className={s.cards}>
            {cart.map((item) => (
              <CardItem
                key={item.id}
                item={item}
                onDelete={(id) => handleDelete(id)}
              />
            ))}
          </div>
        </div>
      ) : user.length > 0 ? (
        <div className={s.content}>
          <div className={s.count}>0</div>
          <img src={cart_img} alt="experiencia" />
        </div>
      ) : (
        cart.length === 0 && (
          <div className={s.content}>
            <div className={s.count}>0</div>
            <img src={cart_img} alt="experiencia" />
            <p className={s.cartText}>No has iniciado sesión</p>
          </div>
        )
      )}
    </div>
  );
};

export default Cart;
