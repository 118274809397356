import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { useWindowDimensions } from '../../hooks/useDimension';
import React, { Fragment, useEffect, useState } from 'react';

import { logoutUser } from '../../api/auth';
import { getCar } from '../../api/reservations';

import s from './header.module.scss';
import logo from '../../../static/icons/logo.png';
import cart from '../../../static/icons/cart.png';

import Cart from '../cart';
import CardHeader from '../card-header';

const Header = ({ title, step, step3, styles }) => {
  const { width } = useWindowDimensions();

  const [user, setUser] = useState('');
  const [cartList, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user.uid);

        getCar(user.uid)
          .then((data) => {
            setCart([...data]);
            setLoading(false);
          })
          .catch((err) => {
            console.log('esate es el error', err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  }, [user]);

  const fetchCar = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user.uid);

        getCar(user.uid)
          .then((data) => {
            setCart([...data]);
            setLoading(false);
          })
          .catch((err) => {
            console.log('esate es el error', err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  const dropdownToggle = () => {
    setDropdown((dropdown) => {
      if (dropdown) {
        return !dropdown;
      } else {
        return dropdown;
      }
    });
  };

  return (
    <Fragment>
      {!loading && (
        <>
          <div className={s.container}>
            <div className={s.primary}>
              <img
                alt="logo"
                role="button"
                src={logo}
                className={s.logo}
                onClick={() => navigate('/home')}
              />

              {title && <div className={s.screen}>{title}</div>}
            </div>

            {step ? (
              step3 ? (
                <img className={s.step3} src={step} style={{ ...styles }} />
              ) : (
                <img className={s.step} src={step} style={{ ...styles }} />
              )
            ) : null}

            <div className={s.inputs}>
              <CardHeader
                color="#ffc72c"
                title={user ? 'Mi perfil' : 'Registrarse'}
                callback={
                  user
                    ? () => navigate('/profile')
                    : () => navigate('/register')
                }
              />

              <CardHeader
                title={
                  !user
                    ? width >= 768
                      ? 'Iniciar Sesión'
                      : 'Ingresar'
                    : width >= 768
                    ? 'Cerrar Sesión'
                    : 'Salir'
                }
                callback={
                  user
                    ? () => {
                        logoutUser().then(navigate('/home'));
                        setCart([]);
                        setUser('');
                      }
                    : () => navigate('/login')
                }
              />

              <CardHeader
                title="FAQ's"
                color="#ffc72c"
                callback={() => navigate('/faqs')}
              />

              <CardHeader
                color="#0c2340"
                callback={() => {
                  if (!dropdown) {
                    setDropdown(true);
                  }
                }}
                title={<img src={cart} alt="cart-logo" className={s.cart} />}
              />
            </div>
          </div>

          {dropdown && (
            <Cart
              user={user}
              cart={cartList}
              dropdown={dropdown}
              fetchCar={fetchCar}
              setDropdown={dropdownToggle}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default Header;
