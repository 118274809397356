import React from 'react';
import s from './index.module.scss';

const CardHeader = ({ title, callback, color }) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        callback();
      }}
      className={s.container}
      style={{ background: color || 'white' }}
    >
      {title}
    </div>
  );
};

export default CardHeader;
